import { domain } from "../helpers/route_helper";

export const fetchUser = id => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/users/${id}`
  });
};

export const fetchUserEmails = id => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/users/email_info`,
    data: { user_id: id }
  });
};

export const addInfo = (parameter, text, user_id, force) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/users/add_info`,
    data: { parameter, text, user_id, force }
  });
};

export const originateMessage = (
  user_id,
  subject,
  message,
  message_type,
  thread
) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/users/originate_message`,
    data: { user_id, subject, message, message_type, thread }
  });
};

export const hotlink = user_id => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/users/hotlink`,
    data: { user_id }
  });
};

export const updateSSN = (user_id, ssn) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/users/update_ssn`,
    data: { ssn, user_id }
  });
};

export const allowNewPlaidConnection = (user_id) => {
  return window.$.ajax({
    method: 'PATCH',
    url: `${domain}/internal/api/users/allow_new_plaid`,
    data: {user_id}
  });
}
