import React from 'react';
import { HierarchicalDropdown } from '../../../components/ui';

const MacroList = ({
  setReplyMessage,
  setMacroId,
  macros,
  macroTree,
  userName
}) => {
  const handleMacroSelect = (macroKey) => {
    setReplyMessage(userName ?
      `Hi ${userName},\n\n${macros[macroKey].text}`
      : `Hi,\n\n${macros[macroKey].text}`
    );
    setMacroId(macros[macroKey].id);
  };

  return (
    <HierarchicalDropdown
      trigger={
        <button className="macro-dropdown-button ui-button bright-ui">
          Select Macro <i className="fas fa-chevron-down"></i>
        </button>
      }
      items={macroTree}
      onSelect={handleMacroSelect}
    />
  );
};

export default MacroList; 