import moment from "moment";

export const formatDate = d => {
  var month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

// Converts YYYYMMDD => MM/DD/YYYY
export const formatYearFirstDate = d => {
  if (!d) return null;

  const year = d.slice(0,4)
  const month = d.slice(4,6)
  const day = d.slice(6,8)
  return [month, day, year].join("/")
};

// Converts YYYY-MM-DD => MM/DD/YYYY
export const formatYearFirstDashDate = d => {
  if (!d) return null;
  
  const cleaned = d.replace(/-/g, "");
  return formatYearFirstDate(cleaned);
}

export const formatJSONDate = d => {
  if (d) {
    return d.split("T")[0];
  } else {
    return null;
  }
};

// e.g. Tuesday, November 28th 2023
export const prettifyDateLong = d => {
  if (d) {
    return moment.utc(d).format("dddd, MMMM Do YYYY");
  } else {
    return null;
  }
};

export const transformAccountInfoDate = (date) => {
  if (!date) {
    return null
  } else {
    let year = date.substring(0,4)
    let month = date.substring(4,6)
    let day = date.substring(6,8)
    return `${month}/${day}/${year}`
  }
}

/**
 * Helper function to transform a timestamp into a human-readable format with timezone
 * 
 * Note: date strings are processed with local timezone, so the output will be relative to the user's local timezone
 * 
 * @param {string} date - ISO timestamp (e.g. 2025-02-26T19:14:36.332Z) or date string (e.g. 2025/02/26)
 * @param {string} outputTimezone - optional timezone to output the date in (e.g. EST)
 * @returns {string} - "MM/DD/YYYY HH:MM (Timezone)"
 */
export const transformTimestamp = (date, outputTimezone) => {
  if (!date) {
    return null;
  }
  
  const dateObj = new Date(date);

  // Use the desired output timezone if passed, otherwise convert to the local timezone
  const timezoneName = outputTimezone || new Intl.DateTimeFormat('en', { timeZoneName: 'short' })
  .formatToParts(dateObj)
  .find(part => part.type === 'timeZoneName')?.value || '';
  
  // Format the date and time parts using Intl.DateTimeFormat
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZone: outputTimezone,
    hour12: true
  }).format(dateObj);
  
  return `${formattedDate} (${timezoneName})`;
}