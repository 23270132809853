import { merge } from "lodash";
import { RECEIVE_MACROS, RECEIVE_MACRO, CATEGORIZE_MACROS } from "../actions/macro_actions";

const defaultProps = {
  macros: {},
  macroTree: {}
};

const macrosReducer = (oldState = defaultProps, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_MACROS:
      return merge({}, oldState, { macros: action.macros });
    case RECEIVE_MACRO:
      return merge({}, oldState, { macros: action.macro });
    case CATEGORIZE_MACROS:
      return merge({}, oldState, { macroTree: action.macroTree });
    default:
      return oldState;
  }
};

export default macrosReducer;
