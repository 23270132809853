import { showFlashMessage } from '../features/flashMessage/flashMessageSlice';

/**
 * Constants for rule IDs
 */
export const RULE_IDS = {
  DEBIT_DETECTION: 'debit-detection',
};

/**
 * Factory function to create the debit detection rule
 * 
 * This rule checks if the reply mentions "debit" when debit is not an allowed payment method
 * for the customer's active loan. If triggered, it displays a warning flash message.
 * 
 * @param {import('../types/replyRules').RuleContextData} contextData - Contextual data from the ticket/customer needed for rule evaluation
 * @returns {import('../types/replyRules').Rule} A fully configured rule object
 */
export const createDebitRule = (contextData) => ({
  id: RULE_IDS.DEBIT_DETECTION,
  name: 'Debit Card Detection',
  description: 'Warns when debit card is mentioned in a reply and debit is not an allowable payment method',
  condition: (reply) => {
    const replyContainsDebit = reply.toLowerCase().includes('debit');
    const hasActiveLoan = contextData.activeLoans.length > 0;
    const debitNotAllowed = !contextData.eligiblePaymentMethods.includes('debit');
    return replyContainsDebit && debitNotAllowed && hasActiveLoan;
  },
  action: (dispatch, _) => {
    dispatch(showFlashMessage({
      message: "Debit is mentioned in the reply but is not an eligible payment method", 
      type: "warning"
    }));
  }
});

/**
 * Creates a complete registry of rules with the provided context data
 * 
 * This function instantiates all rule factories with the given context data
 * and returns them as an array. As more rules are added to the system,
 * they should be included in this registry.
 * 
 * @param {import('../types/replyRules').RuleContextData} contextData - Contextual data from the ticket/customer needed for rule evaluation
 * @returns {Array<import('../types/replyRules').Rule>} An array of configured rule objects
 */
export const createRuleRegistry = (contextData) => {
  return [createDebitRule(contextData)];
}; 