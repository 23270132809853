/**
 * Creates a categorized tree structure from macros based on their associatedTags
 * @param {Object} macros - Object containing all macros
 * @returns {Object} Tree structure with categories as keys and arrays of macro keys as values
 */
export const createMacroTree = macros => {
  // If no macros, return empty object
  if (!macros || Object.keys(macros).length === 0) {
    return {};
  }

  const tree = {};
  
  Object.keys(macros).sort().forEach(macroKey => {
    const macro = macros[macroKey];
    
    // Add to All Macros category
    tree["All Macros"] = (tree["All Macros"] || new Set()).add(macroKey);
    
    if (macro.associatedTags && macro.associatedTags.length > 0) {
      // Process each tag
      macro.associatedTags.forEach(tag => {
        tree[tag] = (tree[tag] || new Set()).add(macroKey);
      });
    } else {
      // Add to Uncategorized
      tree["Uncategorized"] = (tree["Uncategorized"] || new Set()).add(macroKey);
    }
  });
  
  // Convert Sets to sorted arrays for final output
  return Object.fromEntries(
    Object.entries(tree).map(([category, macroSet]) => [
      category, 
      [...macroSet].sort()
    ])
  );
};