import * as APIUtil from "../util/macro_api_util.js";
import { createMacroTree } from '../util/macro_util';

export const RECEIVE_MACROS = "RECEIVE_MACROS";
export const RECEIVE_MACRO = "RECEIVE_MACRO";
export const CATEGORIZE_MACROS = "CATEGORIZE_MACROS";

export const receiveMacros = macros => ({
  type: RECEIVE_MACROS,
  macros
});

export const receiveMacro = macro => ({
  type: RECEIVE_MACRO,
  macro
})

export const categorizeMacros = macroTree => ({
  type: CATEGORIZE_MACROS,
  macroTree
});

export const updateMacro = (title, body, tags) => (dispatch, getState) => (
  APIUtil.updateMacro(title, body, tags).then(
    macro => {
      dispatch(receiveMacro(macro));
      const updatedMacros = getState().entities.macros.macros;
      const updatedMacroTree = createMacroTree(updatedMacros);
      dispatch(categorizeMacros(updatedMacroTree));
    }
  )
)

export const fetchMacros = () => dispatch => (
  APIUtil.fetchMacros().then(
    macros => {
      dispatch(receiveMacros(macros));
      const macroTree = createMacroTree(macros);
      dispatch(categorizeMacros(macroTree));
    }
  )
)
