// creates the base api service for internal
// feature-specific endpoints should be injected into this service: https://redux-toolkit.js.org/rtk-query/usage/code-splitting
// createApi should likely NOT be used again anywhere in the app
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { domain } from "../helpers/route_helper";
import { SESSION_COOKIE, readCookie } from "../helpers/cookie_helper";
import { signOut } from '../features/sessions/sessionSlice';

const baseQuery = fetchBaseQuery({ 
  baseUrl: `${domain}/internal/api/`,
  prepareHeaders: (headers, { getState }) => {
    const token = readCookie(SESSION_COOKIE.API_TOKEN);
    if (token) {
      headers.set('Authorization', 'Bearer ' + token);
    }
    return headers;
  }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  
  if (result.error && result.error.status === 401) {
    api.dispatch(signOut());
  }
  return result;
}

// initialize an empty base api service; endpoints can be injected as needed via injectEndpoints
export const internalApi = createApi({
  reducerPath: 'internalApi',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: ['Dispute', 'Disputes'],
})