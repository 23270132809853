import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import {
  fetchLoanInfo,
  createEarlyRepay,
  updatePaymentDate,
  cancelEarlyRepay,
  reOpenApproval,
  togglePaymentMethod,
  smallerPayment,
  creditBounceFee,
  toggleACH,
  settle,
} from "../../../actions/ticket_actions";
import { appeal } from "../../../actions/loan_actions";
import { formatJSONDate } from "../../../helpers/date_helper";
import { domain } from "../../../helpers/route_helper";
import { copyPaymentInfo, copyString } from "../../../helpers/ticket_helper";
import Loader from "react-loader";
import moment from "moment";
import { motion } from "framer-motion";
import DebouncedButton from "../../DebouncedButton";
import Payment from "./loaninfo/Payment";

const LoanInfo = ({
  id,
  loanInfo,
  fetchLoanInfo,
  createEarlyRepay,
  cancelEarlyRepay,
  updatePaymentDate,
  addResponse,
  reOpenApproval,
  togglePaymentMethod,
  toggleACH,
  smallerPayment,
  creditBounceFee,
  setReplyMessage,
  macros,
  setMacroId,
  settle,
}) => {
  const loans = Object.values(loanInfo).sort((a, b) => b.id - a.id);

  const [isOpen, setIsOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [showAll, setShowAll] = useState(false);

  return (
    <motion.div
      initial={"closed"}
      animate={expanded ? "open" : "closed"}
      variants={collapseVariants}
      className="loan-info"
    >
      <div className="section-title-wrapper">
        <motion.h2
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5 }}
          className="section-title"
        >
          Loan Information
        </motion.h2>
        <i
          onClick={() => {
            setExpanded((s) => !s);
          }}
          className={`expand-icon fas fa-expand`}
        />
      </div>
      <div className="loan-info-loans-wrapper">
        {loans.map(
          (loan, i) =>
            (getLoanDetails(loan)[0] === "ACTIVE" || showAll || i < 1) && (
              <Loan
                i={i}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                key={loan.id}
                id={id}
                loan={loan}
                fetchLoanInfo={fetchLoanInfo}
                createEarlyRepay={createEarlyRepay}
                updatePaymentDate={updatePaymentDate}
                addResponse={addResponse}
                cancelEarlyRepay={cancelEarlyRepay}
                reOpenApproval={reOpenApproval}
                togglePaymentMethod={togglePaymentMethod}
                toggleACH={toggleACH}
                expanded={expanded}
                smallerPayment={smallerPayment}
                creditBounceFee={creditBounceFee}
                setReplyMessage={setReplyMessage}
                macros={macros}
                setMacroId={setMacroId}
                settle={settle}
              />
            )
        )}
        {loans?.length > 1 && (
          <p className="show-all-loans" onClick={() => setShowAll((s) => !s)}>
            {showAll ? "Hide History" : "Show History"}
          </p>
        )}
      </div>
    </motion.div>
  );
};

const Loan = ({
  id,
  loan,
  fetchLoanInfo,
  createEarlyRepay,
  cancelEarlyRepay,
  updatePaymentDate,
  addResponse,
  reOpenApproval,
  togglePaymentMethod,
  toggleACH,
  smallerPayment,
  settle,
  creditBounceFee,
  setReplyMessage,
  macros,
  setMacroId,
  i,
}) => {
  const [requested, setRequested] = useState(false);
  const [expandLoan, setExpandLoan] = useState(false);
  const [copyable, setCopyable] = useState(null);
  const [status, setStatus] = useState(null);
  const [loanDate, setLoanDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [settleAmt, setSettleAmt] = useState(null);
  const [showSettlementBtn, setShowSettlementBtn] = useState(false);

  // (MD) It looks like this view gets data from the backend in the following sequence:
  // 1) Fetch ticket IDs
  // 2) Fetch data for ticket IDs (this includes the initial state of the `loan` object)
  // 3) Fetch additional data for the `loan` including payments

  // This side effect runs whenever the `loan` object is updated.
  useEffect(() => {
    /* Conditional logic for showing the Settlements button */
    // First, make sure that the loan object contains maturity & payment info
    let maturityDateAndPaymentInfoAvail =
      loan?.maturity && loan?.additional?.payments;
    if (maturityDateAndPaymentInfoAvail) {
      // Only show the settlements button if:
      // 1) The loan is past its maturity date
      let loanMaturityDate = new Date(Date.parse(loan.maturity));
      let today = new Date();
      let loanIsMature = today >= loanMaturityDate;
      if (loanIsMature) {
        // 2) None of its payments are currently processing
        let noPaymentsProcessing = Object.values(
          loan.additional.payments
        ).every((p) => !p.processing);
        if (noPaymentsProcessing) {
          setShowSettlementBtn(true);
        }
      }
    }
  }, [loan]);

  // This side effect runs on inital + update renders
  useEffect(() => {
    if (!loan.additional && !requested && expandLoan) {
      setRequested(true);
      fetchLoanInfo(id, loan.id);
    }

    if (!status) {
      const [status, loanDate] = getLoanDetails(loan);
      setStatus(status);
      setLoanDate(loanDate);
    }
  });

  useEffect(() => {
    if (status === "ACTIVE") {
      if (!loan.additional) {
        fetchLoanInfo(id, loan.id);
      } else {
        setExpandLoan(true);
      }
    }
  }, [status, loan.additional]);

  const handleToggleAll = () => {
    setLoading(true);
    togglePaymentMethod(
      id,
      Object.values(loan.additional.payments || []).map((p) => p.id)
    ).then((res) => {
      if (macros["update_payment_method"]) {
        setReplyMessage(macros["update_payment_method"].text);
        setMacroId(macros["update_payment_method"].id);
      }
      setLoading(false);
    });
  };

  const handleSettle = () => {
    setLoading(true);
    setSettleAmt(null);
    settle(id, { loan: loan.id, amt: settleAmt }).then((res) => {
      setLoading(false);
    });
  };

  const handleCopy = (e) => {
    switch (e ? e : copyable ? copyable : null) {
      case "schedule":
        copyPaymentInfo(
          Object.values(loan.additional.payments).filter((p) => !p.paid)
        );
        break;
      case null:
        break;
      default:
        copyString(loan.additional.links[e ? e : copyable ? copyable : null]);
        break;
    }
  };

  return (
    <>
      <motion.li
        className={`
            ${!expandLoan && "closed"}
            ${
              loan.type === "CreditBuilder"
                ? "creditbuilder"
                : loan.type === "CommunityLoan"
                ? "community"
                : (status === "ACTIVE" || status === "ACCEPTED") &&
                  "traditional"
            }
            loan-list-item
          `}
        key={loan.id}
      >
        <p style={{ fontWeight: "bold" }}>
          {loan.approved && !loan.accepted && "Approved - Not Accepted"}
        </p>
        <p
          onClick={() => {
            setExpandLoan((e) => !e);
          }}
          className="loan-summary"
        >
          <span style={{ fontSize: "1rem", fontStyle: "italic" }}>
            ID: {loan.id}{" "}
          </span>
          <br />
          {status} |{" "}
          {loan.type
            ? `${loan.type === "CreditBuilder" ? "Credit Builder" : "Fig 36"} |`
            : `Fig Loan |`}{" "}
          ${loan.cash_advanced} {!loan.ach && loan.funded && "| DEBITS OFF"}
          <br />
          {loanDate}
          <br />
          {loan.approved && loan.closed_at && !loan.funded && i === 0 && (
            <motion.button
              whileTap={{ scale: 0.9 }}
              className="ui-button appeal"
              onClick={() => reOpenApproval(id, loan.id)}
            >
              Reopen
            </motion.button>
          )}{" "}
          {loan.denied && i === 0 && (
            <motion.button
              whileTap={{ scale: 0.9 }}
              className="ui-button appeal"
              onClick={() => appeal(loan.id)}
            >
              Appeal
            </motion.button>
          )}
        </p>
      </motion.li>
      {expandLoan && loan.additional ? (
        <motion.li className="loan-list-item-info">
          {loan.additional.funding_method && (
            <li>
              Funded ${loan.additional.funding_method.amount} via{" "}
              {loan.additional.funding_method.type} (
              {loan.additional.funding_method.account}) on{" "}
              {moment.utc(loan.funded).format("MM-DD-YY")}
            </li>
          )}
          {loan.additional.approval_code && !loan.accepted && (
            <li>Approval Code: {loan.additional.approval_code}</li>
          )}
          {loan?.bounces?.length >= 1 && (
            <p>
              <i>Last 3 Bounces</i>
            </p>
          )}
          {loan?.bounces?.map((bounce, i) => (
            <p
              className={
                ["R07", "R10", "R08"].includes(bounce[0]) ? "high_risk" : ""
              }
            >
              {bounce[0]} - {bounce[3]} - {formatJSONDate(bounce[2])}-{" "}
              {bounce[1]}
            </p>
          ))}
          <div className="loan-list-icon-container">
            <li className="loan-list-icon apr">
              APR:
              <br />
              {Math.round(loan.apr * 100)}%
            </li>
            <li className="loan-list-icon ach">
              <p>Auto ACH</p>
              <DebouncedButton
                className={`ui-button ${loan.ach ? "enabled" : "disabled"}`}
                onClick={async () => {
                  toggleACH(id, loan.id);
                  if (loan.ach) {
                    setReplyMessage("#achrevoke");
                  }
                }}
              >
                {loan.ach ? "Enabled" : "Disabled"}
              </DebouncedButton>
            </li>
            <li className="loan-list-icon balance">
              <Balance
                id={id}
                balance={loan.additional.current_balance}
                loanId={loan.id}
                createEarlyRepay={createEarlyRepay}
                addResponse={addResponse}
                macros={macros}
                setMacroId={setMacroId}
                setReplyMessage={setReplyMessage}
              />
            </li>
            <li className="loan-list-icon copy">
              <select
                className="loan-select"
                value={copyable}
                onChange={(e) => {
                  if (e.target.value) {
                    handleCopy(e.target.value);
                  } else {
                    setCopyable(e.target.value);
                  }
                }}
              >
                <option value={null} selected disabled>
                  Copy...
                </option>
                {loan.additional.payments && (
                  <option value="schedule">schedule</option>
                )}
                {Object.keys(loan.additional.links).map((link) =>
                  loan.additional.links[link] ? (
                    <option value={link}>{link}</option>
                  ) : null
                )}
              </select>
            </li>
          </div>
          {loan?.additional?.payments?.length >= 1 && <p>Payment Schedule:</p>}
          <div className="loan-payment-container">
            <li className="loan-list-icon toggle">
              {!loading && (
                <DebouncedButton
                  className="ui-button"
                  onClick={handleToggleAll}
                >
                  Toggle All Methods
                </DebouncedButton>
              )}
            </li>
            {Object.values(loan.additional.payments || [])
              .sort(
                (a, b) =>
                  new Date(formatJSONDate(a.due)) -
                  new Date(formatJSONDate(b.due))
              )
              .map(
                (pmt) =>
                  !pmt.paid && (
                    <Payment
                      id={id}
                      pmt={pmt}
                      loan={loan}
                      updatePaymentDate={updatePaymentDate}
                      addResponse={addResponse}
                      cancelEarlyRepay={cancelEarlyRepay}
                      togglePaymentMethod={togglePaymentMethod}
                      smallerPayment={smallerPayment}
                      creditBounceFee={creditBounceFee}
                      macros={macros}
                      setMacroId={setMacroId}
                      setReplyMessage={setReplyMessage}
                    />
                  )
              )}
            {loan?.additional?.payments && <div className="payment-divider" />}
            {Object.values(loan.additional.payments || [])
              .sort(
                (a, b) =>
                  new Date(formatJSONDate(a.due)) -
                  new Date(formatJSONDate(b.due))
              )
              .reverse()
              .map(
                (pmt) =>
                  pmt.paid && (
                    <Payment
                      id={id}
                      pmt={pmt}
                      updatePaymentDate={updatePaymentDate}
                      addResponse={addResponse}
                      cancelEarlyRepay={cancelEarlyRepay}
                      togglePaymentMethod={togglePaymentMethod}
                      smallerPayment={smallerPayment}
                      creditBounceFee={creditBounceFee}
                    />
                  )
              )}
            {showSettlementBtn && loan.additional.settlementMin && (
              <li>
                (minimum: ${loan.additional.settlementMin}){" "}
                <input
                  type="text"
                  value={settleAmt}
                  onChange={(e) => setSettleAmt(e.target.value)}
                />
                {!loading && (
                  <DebouncedButton
                    className={`ui-button`}
                    onClick={handleSettle}
                    placeholder="Settle Amt"
                  >
                    Settle
                  </DebouncedButton>
                )}
              </li>
            )}
          </div>
        </motion.li>
      ) : null}
    </>
  );
};

const Balance = ({
  id,
  balance,
  loanId,
  createEarlyRepay,
  addResponse,
  macros,
  setMacroId,
  setReplyMessage,
}) => {
  const [showCalc, setShowCalc] = useState(false);
  const [date, setDate] = useState(null);
  const [earlyRepay, setEarlyRepay] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (date) calcEarlyRepay();
  }, [date]);

  if (balance <= 0) return null;

  const calcEarlyRepay = () => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/early_repay_balance`,
      data: { loan_id: loanId, date },
    }).then((payload) => {
      setEarlyRepay(payload.balance);
      let replyMessage = macros["calc_early_repay"].text;
      replyMessage = replyMessage.replace(
        "_",
        moment.utc(date).format("dddd, MMMM Do YYYY")
      );
      replyMessage = replyMessage.replace("_", payload.balance);
      setReplyMessage(replyMessage);
      setMacroId(macros["calc_early_repay"].id);
    });
  };

  const handleEarly = () => {
    setLoading(true);
    createEarlyRepay(id, loanId, date).then(() => {
      setShowCalc(false);
      setLoading(false);
      let replyMessage = macros["set_early_repay"].text;
      replyMessage = replyMessage.replace("_", earlyRepay);
      replyMessage = replyMessage.replace(
        "_",
        moment.utc(date).format("dddd, MMMM Do YYYY")
      );
      setReplyMessage(replyMessage);
      setMacroId(macros["set_early_repay"].id);
    });
  };

  return (
    <div>
      <li onContextMenu={() => setShowCalc(true)}>
        Balance: <br />${balance}
      </li>
      {showCalc ? (
        <Fragment>
          <input
            data-loan={loanId}
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <b>${earlyRepay}</b>
          {earlyRepay ? (
            <Loader loaded={!loading} options={loaderOptions}>
              <DebouncedButton onClick={handleEarly}>Submit</DebouncedButton>
            </Loader>
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
};

const getLoanDetails = (loan) => {
  let status;
  let loanDate;

  if (loan.denied) {
    loanDate = loan.denied;
    status = "DENIED";
  } else if (loan.paid_off) {
    loanDate = loan.paid_off;
    status = "PAID OFF";
  } else if (loan.funded) {
    loanDate = loan.funded;
    status = "ACTIVE";
  } else if (loan.closed_at) {
    loanDate = loan.closed_at;
    status = "CLOSED/CANCELLED";
  } else if (loan.accepted) {
    loanDate = loan.accepted;
    status = "ACCEPTED";
  } else if (loan.approved) {
    loanDate = loan.approved;
    status = "APPROVED";
  } else if (loan.applied) {
    loanDate = loan.applied;
    status = "APPLIED";
  }

  loanDate = moment.utc(loanDate).format("dddd, MMMM Do YYYY");

  return [status, loanDate];
};

const loaderOptions = {
  lines: 13,
  length: 4,
  width: 2,
  radius: 6,
  scale: 1.0,
  corners: 1,
  color: "#41b29e",
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 0,
  shadow: false,
  hwaccel: false,
  position: "relative",
  // left: "10%"
};

const collapseVariants = {
  open: {
    height: "60vh",
  },
  closed: {},
};

const mapStateToProps = (state) => ({ macros: state.entities.macros.macros });

const mapDispatchToProps = (dispatch) => ({
  fetchLoanInfo: (id, loanId) => dispatch(fetchLoanInfo(id, loanId)),
  createEarlyRepay: (id, loanId, date) =>
    dispatch(createEarlyRepay(id, loanId, date)),
  cancelEarlyRepay: (id, pmtId) => dispatch(cancelEarlyRepay(id, pmtId)),
  updatePaymentDate: (id, pmtId, date) =>
    dispatch(updatePaymentDate(id, pmtId, date)),
  reOpenApproval: (id, loanId) => dispatch(reOpenApproval(id, loanId)),
  togglePaymentMethod: (id, pmtId) => dispatch(togglePaymentMethod(id, pmtId)),
  appeal: (loanId) => dispatch(appeal(loanId)),
  smallerPayment: (id, pmtId, amt) => dispatch(smallerPayment(id, pmtId, amt)),
  creditBounceFee: (id, pmtId) => dispatch(creditBounceFee(id, pmtId)),
  toggleACH: (id, loanId) => dispatch(toggleACH(id, loanId)),
  settle: (id, settlement) => dispatch(settle(id, settlement)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanInfo);
