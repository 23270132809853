import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createRuleRegistry } from '../config/replyRules';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

/**
 * Custom hook that evaluates reply text against a set of rules and triggers actions when conditions are met.
 * 
 * @param {string} reply - The text content of the reply being evaluated
 * @param {string} ticketId - The ID of the ticket associated with this reply
 * @returns {import('../types/replyRules').ReplyRulesResult} An object containing the triggered rules and methods to manage them
 */
export function useReplyRules(reply, ticketId) {
  const dispatch = useDispatch();
  const [triggeredRules, setTriggeredRules] = useState(new Set());
  
  /**
   * Extract relevant data from the Redux store to provide context for rule evaluation.
   * This includes information like eligible payment methods from active loans.
   */
  const contextData = useSelector((state) => {
    const ticket = state.entities.tickets.tickets[ticketId];

    // Convert loans object to array
    const loans = ticket?.loans ? Object.values(ticket.loans) : [];
  
    // Filter for only active, funded loans that haven't been paid off
    const activeLoans = loans.filter(loan => (!!loan.funded) && (loan.paid_off === null));

    // Collect all eligible payment methods from active loans
    const eligiblePaymentMethods = activeLoans.flatMap(loan => 
      loan.eligible_payment_methods || []
    );

    // Remove duplicates from payment methods
    const uniquePaymentMethods = [...new Set(eligiblePaymentMethods)];

    return {
      eligiblePaymentMethods: uniquePaymentMethods,
      activeLoans: activeLoans
    };
  });
  
  /**
   * Create the rule registry based on the context data.
   * Memoized to prevent unnecessary recreations when context hasn't changed.
   */
  const ruleRegistry = useMemo(() => 
    createRuleRegistry(contextData), 
    [contextData]
  );
  
  /**
   * Effect that evaluates each rule whenever the reply text or rule registry changes.
   * Triggers actions for rules whose conditions are met and haven't been triggered yet
   * Removes rules from triggeredRules when their conditions are no longer met
   */
  useEffect(() => {
    if (!reply) return;

    // Process each rule in the registry
    ruleRegistry.forEach(rule => {
      const { id, condition, action } = rule;

      const shouldBeTriggered = condition(reply);
      const isTriggered = triggeredRules.has(id);

      // If the rule should be triggered but is not already
      // OR should no longer be triggered but is, update the triggeredRules set
      if (shouldBeTriggered !== isTriggered) {
        if (shouldBeTriggered) {
          // Execute the rule's action
          action(dispatch, reply);
          setTriggeredRules(prev => new Set(prev).add(id));
        } else {
          // Reset the rule by removing it from the triggered rules set
          setTriggeredRules(prev => {
            const newSet = new Set(prev);
            newSet.delete(id);
            return newSet;
          });
        }
      }
    });
  }, [reply, dispatch, ruleRegistry]);

  return {
    /**
     * Set of rule IDs that have been triggered and not reset
     */
    triggeredRules,
    
    /**
     * Checks if a specific rule has been triggered
     * @param {string} ruleId - The ID of the rule to check
     * @returns {boolean} True if the rule has been triggered, false otherwise
     */
    isRuleTriggered: (ruleId) => triggeredRules.has(ruleId)
  };
} 