import { connect } from "react-redux";
import { selectUserLoans } from "../reducers/selectors";
import UserShow from "./UserShow";
import { search } from "../actions/search_actions";
import { changeLoading } from "../actions/loader_actions";
import { withRouter } from "react-router";

const mapStateToProps = (state, ownProps) => {
  return {
    userInfo: state.entities.user,
    loans: selectUserLoans(state.entities.loans, state.entities.user.loan_ids),
    errors: state.errors.edit,
    macros: state.entities.macros.macros
  };
};

const mapDispatchToProps = dispatch => ({
  search: (parameter, text) => dispatch(search(parameter, text)),
  load: () => dispatch(changeLoading(true))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserShow)
);
