import { createSlice } from "@reduxjs/toolkit";
import { sendMailer, lookup, fileBankruptcy, dischargeBankruptcy } from "../../thunks/fcraThunks";

// fcra lookup
export const lookupLoadingStatus = state => state.entities.fcra.lookupLoading;
export const accountInformation = state => state.entities.fcra.searchedLoan.accountInfo;
export const consumerInformation = state => state.entities.fcra.searchedLoan.consumerInfo;
export const consumerNameInformation = state => state.entities.fcra.searchedLoan.consumerInfo.consumerNameInfo;
export const consumerAddressInformation = state => state.entities.fcra.searchedLoan.consumerInfo.consumerAddressInfo;
export const searchedLoanIdNum = state => state.entities.fcra.searchedLoan.loanId;
export const bankruptcyDetail = state => state.entities.fcra.userDetail.bankruptcyDetail;
export const covidDetail = state => state.entities.fcra.userDetail.covidDetail;
export const userDetail = state => state.entities.fcra.userDetail;
export const userComments = state => state.entities.fcra.userDetail.comments;
export const pastAdjustments = state => state.entities.fcra.searchedLoan.pastAdjustments;
// the refi chain includes the searched loan itself! so if this isn't a refi, it will still contain (1) loan id
export const refiChain = state => state.entities.fcra.searchedLoan.refiChain;
export const searchedLoanPaymentDetails = state => state.entities.fcra.searchedLoan.paymentDetail;
export const additionalLoans = state => state.entities.fcra.additionalLoans;
export const lookupErrors = state => state.entities.fcra.errors;

// mailer processing
export const mailerProcessingLoading = state => state.entities.fcra.mailerSend.mailerSendLoading;
export const mailerProcessingErrors = state => state.entities.fcra.mailerSend.mailerSendErrors;

const initialEmptyState = {
  searchedLoan: {
    loanId: null,
    pastAdjustments: [],
    accountInfo: {},
    consumerInfo: {},
    paymentDetail: {}
  },
  userDetail: {
    id: null,
    dnc: null,
    dnl: null,
    bankruptcyDetail: {},
    covidDetail: null
  },
  additionalLoans: {},
  errors: null,
  lookupLoading: false,
  bankruptcyProcessing: {
    loading: false,
    errors: null,
    open: false
  },
  mailerSend: {
    mailerSendLoading: false,
    mailerSendErrors: null
  }
}

export const fcraSlice = createSlice({
  name: "fcra",
  initialState: initialEmptyState,
  reducers: {
    clearBankruptcyErrors(state) {
      state.bankruptcyProcessing.errors = null;
    },  
    clearMailerErrors(state) {
      state.mailerSend.mailerSendErrors = null;
    },
    clearLookupErrors(state) {
      state.errors = null;
    },
    openBankruptcyModal(state) {
      state.bankruptcyProcessing.open = true;
    },
    closeBankruptcyModal(state) {
      state.bankruptcyProcessing.open = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(lookup.pending, (state, { payload }) => {
        state.errors = null;
        state.lookupLoading = true;
      })
      .addCase(lookup.fulfilled, (state, { payload }) => {
        state.lookupLoading = false;

        const { data } = payload;
        if (!data) return;

        const { searched_loan, user_detail, additional_loans } = data;

        if (searched_loan) {
          state.searchedLoan = {
            loanId: searched_loan.searched_loan_id,
            pastAdjustments: searched_loan.past_adjustments,
            refiChain: searched_loan.refi_chain_ids,
            accountInfo: searched_loan.account_info,
            consumerInfo: searched_loan.consumer_info,
            paymentDetail: searched_loan.payment_detail,
          };
        }

        if (user_detail) {
          state.userDetail = {
            id: user_detail.id,
            dnc: user_detail.dnc,
            dnl: user_detail.dnl,
            bankruptcyDetail: user_detail.bankruptcies_detail,
            covidDetail: user_detail.covid_flag,
            comments: user_detail.comments,
          };
        }

        state.additionalLoans = additional_loans || {};
      })
      .addCase(lookup.rejected, (state, { payload }) => {
        state.lookupLoading = false;
        state.errors = payload.errors;
        state.searchedLoan = initialEmptyState.searchedLoan;
        state.userDetail = initialEmptyState.userDetail;
        state.additionalLoans = initialEmptyState.additionalLoans;
      })
      .addCase(fileBankruptcy.pending, (state, { payload }) => {
        state.bankruptcyProcessing.loading = true;
        state.bankruptcyProcessing.errors = null;
      })
      .addCase(fileBankruptcy.fulfilled, (state, { payload }) => {
        state.bankruptcyProcessing.loading = false;
      })
      .addCase(fileBankruptcy.rejected, (state, { payload }) => {
        state.bankruptcyProcessing.loading = false;
        state.bankruptcyProcessing.errors = payload.errors;
      })
      .addCase(dischargeBankruptcy.pending, (state, { payload }) => {
        state.bankruptcyProcessing.loading = true;
        state.bankruptcyProcessing.errors = null;
      })
      .addCase(dischargeBankruptcy.fulfilled, (state, { payload }) => {
        state.bankruptcyProcessing.loading = false;
      })
      .addCase(dischargeBankruptcy.rejected, (state, { payload }) => {
        state.bankruptcyProcessing.loading = false;
        state.bankruptcyProcessing.errors = payload.errors;
      })
      .addCase(sendMailer.pending, (state, { payload }) => {
        state.mailerSend.mailerSendLoading = true;
        state.mailerSend.mailerSendErrors = null;
      })
      .addCase(sendMailer.fulfilled, (state, { payload }) => {
        state.mailerSend.mailerSendLoading = false;
      })
      .addCase(sendMailer.rejected, (state, { payload }) => {
        state.mailerSend.mailerSendLoading = false;
        state.mailerSend.mailerSendErrors = payload.errors;
      });
  }
})

export const { clearBankruptcyErrors, clearMailerErrors, clearLookupErrors, openBankruptcyModal, closeBankruptcyModal } = fcraSlice.actions

export default fcraSlice.reducer;