import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchMacros } from "../../../actions/macro_actions";
import { domain } from "../../../helpers/route_helper";
import Loader from "react-loader";
import { closeTicket } from "../../../actions/ticket_actions";
import { readCookie } from "../../../helpers/cookie_helper";
import MacroList from './MacroList';

const Reply = ({
  responses,
  macros,
  fetchMacros,
  id,
  handleSent,
  closeTicket,
  submit,
  replyMessage,
  setReplyMessage,
  macroId,
  setMacroId,
  filterId,
  setFilterId,
  userName,
  macroTree
}) => {
  const [loading, setLoading] = useState(false);

  const handleSend = () => {
    setLoading(true);
    submit();
    sendReply(id, replyMessage, macroId, filterId).then(
      () => {
        handleSent({ replyMessage, time: getCurrentTime() });
        setReplyMessage("");
        setLoading(false);
        closeTicket(id);
      },
      errors => {
        handleSent({ replyMessage, time: getCurrentTime() });
        setLoading(false);
        alert(errors.responseJSON.errors);
      }
    );
  };

  const autoPopulateReply = id => {
    let stringId = id?.toString();
    let cookieMessage = readCookie(stringId);
    if (cookieMessage) {
      setReplyMessage(cookieMessage);
    }
  };

  useEffect(() => {
    if (!replyMessage) {
      autoPopulateReply(id);
    }
  }, []);

  // fetch macros if not loaded
  useEffect(() => {
    if (Object.keys(macros).length === 0) {
      fetchMacros();
    }
  }, [macros]);

  useEffect(() => {
    if (userName) {
      setReplyMessage(`Hi ${userName},\n\n`)
    }
  }, [userName]);

  return (
    <>
      <div className="reply">
        <ReplyBox
          replyMessage={replyMessage}
          setReplyMessage={setReplyMessage}
          macros={macros}
          setMacroId={setMacroId}
        />
        <div className="horizontal-display">
          <MacroList
            macros={macros}
            setReplyMessage={setReplyMessage}
            setMacroId={setMacroId}
            userName={userName}
            macroTree={macroTree}
          />
          <Loader options={loaderOptions} loaded={!loading}>
            <Send handleSend={handleSend} />
          </Loader>
        </div>
      </div>
      <div className="suggested-container">
        <SuggestedResponses
          responses={responses}
          setReplyMessage={setReplyMessage}
          setFilterId={setFilterId}
        />
      </div>
    </>
  );
};

const ReplyBox = ({ replyMessage, setReplyMessage, setMacroId, macros }) => {
  const handleKey = e => {
    const macroRegex = /(?:|^)#[A-Za-z0-9\-\.\_]+(?:|$)/;
    let snippet = replyMessage.match(macroRegex);
    if (e.key === "Enter" && snippet && macros[snippet[0].slice(1)]) {
      const macroName = snippet[0].slice(1);
      // this.applyMacro(macroName, replyCopy, setReply, false);
      setReplyMessage(replyMessage.replace(macroRegex, macros[macroName].text));
      setMacroId(macros[macroName].id);
    }
  };

  return (
    <textarea
      placeholder={"Hello customer..."}
      value={replyMessage}
      onChange={e => setReplyMessage(e.target.value)}
      onKeyPress={handleKey}
    />
  );
};

const Send = ({ handleSend }) => {
  return (
    <motion.button
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "center"
      }}
      className="ui-button"
      initial={{ scale: 1 }}
      whileTap={{ scale: 0.9 }}
      onClick={handleSend}
    >
      Send<i className="fas fa-paper-plane button-icon"></i>
    </motion.button>
  );
};

const SuggestedResponses = ({ responses, setReplyMessage, setFilterId }) => {
  return (
    <ul className="suggested">
      {Array.from(responses).map((r, i) => {
        let response;
        let filter_partial;
        if (Array.isArray(r)) {
          response = r[0];
          filter_partial = r[1];
        } else {
          response = r;
          filter_partial = null;
        }
        return (
          <li
            onClick={() => {
              setReplyMessage(response);
              setFilterId(filter_partial);
            }}
            key={i}
          >
            {response && response.length > 200
              ? `${response.slice(0, 140)}...`
              : response}
          </li>
        );
      })}
    </ul>
  );
};

const sendReply = (id, reply, macro_id, filter_id) =>
  window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/send_reply`,
    data: { id, reply, macro_id, filter_id }
  });

const loaderOptions = {
  lines: 13,
  length: 4,
  width: 2,
  radius: 6,
  scale: 1.0,
  corners: 1,
  color: "#41b29e",
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 0,
  shadow: false,
  hwaccel: false,
  position: "relative"
  // left: "10%"
};

const getCurrentTime = () =>
  new Date().toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });

const mapStateToProps = state => ({
  macros: state.entities.macros.macros,
  macroTree: state.entities.macros.macroTree
});

const mapDispatchToProps = dispatch => ({
  fetchMacros: () => dispatch(fetchMacros()),
  closeTicket: id => dispatch(closeTicket(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reply);
