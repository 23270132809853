import React, { useState, useRef, useEffect } from 'react';
import './HierarchicalDropdown.css';

/**
 * A hierarchical dropdown component that supports multiple levels of navigation
 * 
 * Currently only supports up-facing dropdowns; to implement down-facing dropdowns,
 * consider adding a direction prop and creating a css class to mirror the menu-up class.
 * 
 * @param {Object} props
 * @param {React.ReactNode} props.trigger - Element that triggers the dropdown
 * @param {Object.<string, Array<string>>} props.items - Hierarchical items object
 * @param {Function} props.onSelect - Callback when an item is selected
 */
const HierarchicalDropdown = ({
  trigger,
  items,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const dropdownRef = useRef(null);
  const categoryRefs = useRef({});
  
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setHoveredCategory(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Get submenu position based on category element
  const getSubmenuPosition = (category) => {
    if (!categoryRefs.current[category]) return {};

    const categoryRect = categoryRefs.current[category].getBoundingClientRect();
    
    return {
      position: 'fixed',
      left: `${categoryRect.right}px`,
      top: `${categoryRect.top}px`,
      right: 'auto'
    };
  };

  const handleCategoryHover = (category) => {
    setHoveredCategory(category);
  };

  const handleSelect = (value) => {
    onSelect(value);
    setIsOpen(false);
    setHoveredCategory(null);
  };

  return (
    <div 
      className="hierarchical-dropdown"
      ref={dropdownRef}
    >
      <div 
        className="dropdown-trigger"
        onClick={() => setIsOpen(!isOpen)}
      >
        {trigger}
      </div>

      {isOpen && (
        <div 
          className="dropdown-menu menu-up"
          style={{ width: 'auto' }}
        >
          {Object.keys(items).sort().map((category) => (
            <div
              key={category}
              className="dropdown-category"
              ref={el => categoryRefs.current[category] = el}
              onMouseEnter={() => handleCategoryHover(category)}
            >
              <div className="dropdown-category-header">
                {category}
                <i className="fas fa-chevron-right"></i>
              </div>
              {hoveredCategory === category && (
                <div 
                  className="dropdown-submenu"
                  style={getSubmenuPosition(category)}
                >
                  {items[category].map((item, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={() => handleSelect(item)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HierarchicalDropdown; 