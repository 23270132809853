import * as APIUtil from "../util/user_api_util";
import { receiveEditErrors } from "./error_actions";
export const RECEIVE_USER = "RECEIVE_USER";

export const receiveUser = user => ({
  type: RECEIVE_USER,
  user
});

export const fetchUser = id => dispatch =>
  APIUtil.fetchUser(id).then(user => {
    dispatch(receiveUser(user));
  });

export const addInfo = (parameter, text, userId, force) => dispatch =>
  APIUtil.addInfo(parameter, text, userId, force ).then(
    payload => dispatch(receiveUser(payload.user)),
    errors => dispatch(receiveEditErrors(errors))
  );

export const hotlink = (user_id, callback) => dispatch =>
  APIUtil.hotlink(user_id).then(
    payload => callback(payload.link),
    errors => dispatch(receiveEditErrors(errors))
  );

export const allowNewPlaidConnection = (user_id) => dispatch => {
  APIUtil.allowNewPlaidConnection(user_id).then(payload => {
    dispatch(receiveUser(payload.user));
  });
}
